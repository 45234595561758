import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import CustomizedDialogs from "../components/CirclerTimer";
import axios from "axios";
// import validate from 'react-joi-validation';
import Joi from "joi";
import "react-datepicker/dist/react-datepicker.css";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { Store, UpdateStore } from "../StoreContext";
import Grid from "@material-ui/core/Grid";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";
// import Dummy from "../assets/img/dummy.png";
import MainMobile from "../assets/img/mobileform.png";
import arrowleft from "../assets/img/arrow2.png";
import scrollToTop from "../utils/ScrollTop";
import DatePicker from "react-datepicker";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import OutlinedInput from "@mui/material/OutlinedInput";
import { styled } from "@mui/material/styles";
import moment from "moment";
import api from "../api/index.js";
import NavigationBlocker from "./navBlocker";
import { Prompt } from "react-router";
import { InputAdornment, TextField } from "@mui/material";

// import MenuItem from "@material-ui/core/MenuItem";
// import Checkbox from '@mui/material/Checkbox';

const UserForm = ({ setStep, setActiveStep }) => {
  // initialize ContextStore

  const updateStore = UpdateStore();
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    contact_no: "",
    address: "",
    email: "",
    total_experience: "",
    post_experience: "",
    current_employer: "",
    // current_salary: "",
    // expected_salary: "",
    // joining_time: "",
    // leaving_reason: "",
  });
  // // state for address options list
  const [values, setValues] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = useState(false);
  console.log("formData", formData);
  // Destructuring of UserForm State
  const {
    firstName,
    // lastName,
    contact_no,
    // address,
    email,
    total_experience,
    // post_experience,
    // joining_time,
    // current_salary,
    // expected_salary,
    // leaving_reason,
  } = formData;

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  //Skills dropdown

  // const names = [
  //   "HTML",
  //   "CSS",
  //   "JavaScript",
  //   "React",
  //   "Node",
  //   "dotNet",
  //   "Native",
  //   "Express",
  // ];

  // const [availableSkills, setAvailableSkills] = useState([]);
  // const [selectedSkills, setSelectedSkills] = useState([]);

  // useEffect(() => {
  //   fetchCategories();
  // }, []);

  // const fetchCategories = async () => {
  //   try {
  //     const res = await api("get", "/category");
  //     const filteredCategories = res?.category.filter(
  //       (category) => category.name !== "Custom Questions"
  //     );
  //     setAvailableSkills(filteredCategories);
  //   } catch (error) {
  //     console.error("Error fetching categories:", error);
  //   }
  // };

  // const handleChange = (event) => {
  //   const {
  //     target: { value },
  //   } = event;
  //   setSelectedSkills(typeof value === "string" ? value.split(",") : value);
  // };

  //tooltip for
  const CustomWidthTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: 500,
    },
  });
  const longText = `
    Note:There is no discrimination based on sect and religion.
    This information is just to know about the specific
    holidays/leaves you might require.
`;

  // Get institutes data from API
  useEffect(() => {
    scrollToTop();
    // getSchoolData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const useStyles = makeStyles({
    customTextField: {
      "& input::placeholder": {
        color: "white",
      },
    },
    option: {
      background: "#242635 ",
      color: "white",
      "&:hover": {
        backgroundColor: "#9198a5 !important",
      },
    },
    noOptions: {
      display: `${values.length <= 0 ? "none" : "inherit"}`,
      color: "white",
    },
    select: {
      background: "#242635",
      color: "#9198a5",
      "&:before": {
        borderColor: "red",
      },
      "&:after": {
        borderColor: "yellow",
      },
    },
  });

  let lastToastTime = 0; // Store the timestamp of the last toast

  // onChange Function for Input Field
  const formatPhoneNumber = (phoneNumber) => {
    const cleaned = phoneNumber.replace(/\D/g, "");
    const match = cleaned.match(/^(\d{0,3})(\d{0,3})(\d{0,4})$/);
    if (match) {
      let formattedNumber = "";
      if (match[1]) {
        formattedNumber += `(${match[1]}`;
      }
      if (match[2]) {
        formattedNumber += `) ${match[2]}`;
      }
      if (match[3]) {
        formattedNumber += `-${match[3]}`;
      }
      return formattedNumber;
    }
    return phoneNumber;
  };

  const handlePhoneChange = (event) => {
    const { value, name } = event.target;
    const cleaned = value.replace(/\D/g, "");

    // Update form data with the actual value if it's numeric

    setFormData((prevData) => ({ ...prevData, [name]: cleaned }));
  };
  const onChange = (e) => {
    const { name, value, checked } = e.target;
    const now = Date.now();
    const isAlphanumeric = /^[a-zA-Z\s.]*$/.test(value);
    const isNumeric = /^\d*$/.test(value);

    // Validation for names (only alphabets and dots allowed)
    if (name === "firstName" || name === "lastName") {
      if (isAlphanumeric) {
        setFormData((prevData) => ({ ...prevData, [name]: value }));
      } else if (now - lastToastTime >= 60000) {
        toast.error("Only alphabets and dots are allowed.");
        lastToastTime = now;
      }
    }
    // Validation for checkboxes (newsletter, accept_condition)
    else if (name === "newsletter" || name === "accept_condition") {
      setFormData((prevData) => ({ ...prevData, [name]: checked }));
    }
    // Validation for contact number (must start with '971' and be numeric)
    else if (name === "contact_no") {
      // Check if the input is numeric and length is within allowed limits
      const isNumeric = /^\d+$/.test(value); // Check if value is numeric
      const isLengthValid = value.length <= 12; // Check if the length is up to 12 characters

      // Allow typing if the value starts with '971' and is numeric, up to 12 characters
      if (
        (value.startsWith("971") || value === "") &&
        isLengthValid &&
        isNumeric
      ) {
        setFormData((prevData) => ({ ...prevData, [name]: value }));
      }
      // Display an error if the value doesn't start with '971'
      else if (!value.startsWith("971") && now - lastToastTime >= 60000) {
        toast.error("Contact number must start with '971'.");
        lastToastTime = now;
      }
      // Display an error if the value is not numeric
      else if (!isNumeric && now - lastToastTime >= 60000) {
        toast.error("Only numbers are allowed in contact number.");
        lastToastTime = now;
      }
    }
    // Validation for numeric fields (salary)
    // else if (name === "current_salary" || name === "expected_salary") {
    //   if (!isNumeric && now - lastToastTime >= 60000) {
    //     toast.error("Only numbers are allowed.");
    //     lastToastTime = now;
    //   } else {
    //     setFormData((prevData) => ({ ...prevData, [name]: value }));
    //   }
    // }
    // Default case for other fields
    else {
      setFormData((prevData) => ({ ...prevData, [name]: value }));
    }
  };

  // function for Date select
  // const onChangeDate = (date) => {
  //   setFormData({ ...formData, dob: date });
  // };

  // const onChangeeDate = (eDate) => {
  //   setFormData({ ...formData, joining_time: eDate });
  // };
  // const onChangeMStatus = (mstatus) => {
  //   setFormData({ ...formData, marital_status: mstatus.target.value });
  // };
  const onChangeTExp = (tstatus) => {
    setFormData({ ...formData, total_experience: tstatus.target.value });
  };
  const onChangePExp = (pstatus) => {
    setFormData({ ...formData, post_experience: pstatus.target.value });
  };

  const prepareDataForSubmission = (formData) => {
    // Add the +971 prefix to the contact_no field
    return {
      ...formData,
      contact_no: formData.contact_no
        ? `+972${formData.contact_no.replace(/\D/g, "")}`
        : "",
    };
  };
  // handleSubmit Function

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Form data on console
    let newformdata = { ...formData };

    console.log("newformdata", newformdata);

    const preparedData = prepareDataForSubmission(newformdata);

    // Validation checks
    if (firstName === "") {
      return toast.error("Please enter name");
    }
    // if (lastName === "") {
    //   return toast.error("Please enter last name");
    // }
    // if (!/^971\d{9}$/.test(preparedData)) {
    //   return toast.error(
    //     "Contact number must start with 971 and be 12 digits long."
    //   );
    // }
    if (contact_no === "") {
      return toast.error("Please enter contact number");
    }
    if (contact_no.length !== 9) {
      return toast.error("Contact number must be 12 digits.");
    }
    if (email === "") {
      return toast.error("Please enter email");
    }
    if (total_experience === "") {
      return toast.error("Please enter your experience");
    }
    // if (post_experience === "") {
    //   return toast.error("Please enter your experience related to post");
    // }
    // Uncomment if joining time is required
    // if (joining_time === "") {
    //   return toast.error("Please enter your expected date of joining");
    // }
    // if (selectedSkills.length === 0) {
    //   return toast.error("Please enter your Test Category");
    // }

    setLoading(true);
    console.log("preparedData", preparedData);
    try {
      const data = await api("put", "/users", preparedData);

      // Success handling
      setOpen(true);
      updateStore({
        questions: data?.allquestions,
        user: data?.user,
      });
      setFormData({
        firstName: "",
        lastName: "",
        contact_no: "",
        email: "",
        selectedSkills: "",
        total_experience: "",
        post_experience: "",
        // joining_time: "",
        // current_salary: "",
        // expected_salary: "",
        // leaving_reason: "",'
      });
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <>
      <Grid container spacing={2} className="mainuserform">
        {/* <Grid item xs={12} sm={4} className="dummyGrid">
          <img src={Dummy} className="dummyimg forweb" />
          <img src={MainMobile} className=" dummyimgforuserform formobile" />
        </Grid> */}
        <Grid item xs={12} sm={8} className="responsiveform">
          <form autoComplete="off" onSubmit={handleSubmit} className="userForm">
            <Grid container className="userformgrid">
              <Grid item xs={12} sm={12} className="">
                <h3 className="formheading">Submission Details</h3>
              </Grid>
              <Grid container spacing={2} className="inputgrid1">
                <Grid item xs={12} sm={6} className="inputGrid">
                  <input
                    name="firstName"
                    type="String"
                    placeholder="Name"
                    className="inputField"
                    value={firstName}
                    onChange={onChange}
                    // required
                    // maxLength={25}
                  />
                </Grid>
                <Grid item xs={12} sm={6} className="inputGrid">
                  {/* <input
                    name="lastName"
                    type="String"
                    placeholder="Last Name"
                    className="inputField"
                    value={lastName}
                    onChange={onChange}
                    // required
                    // maxLength={25}
                  /> */}
                  <div
                    class="inputField"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginLeft: "13px",
                    }}
                  >
                    <div class="country-code">+972</div>
                    <input
                      name="contact_no"
                      type="text"
                      id="phoneInput"
                      value={contact_no}
                      class="inputField1"
                      // placeholder="xxx-xxx-xxx"
                      onChange={handlePhoneChange}
                      maxlength="9"
                      style={{ border: "none", width: "100%", outline: "none" }}
                    />
                  </div>
                </Grid>
              </Grid>
              <Grid container spacing={2} className="inputgrid1">
                <Grid item xs={12} sm={6} className="inputGrid">
                  <input
                    type="email"
                    name="email"
                    placeholder="Email"
                    className="inputField"
                    value={email}
                    onChange={onChange}
                    // required
                  />
                </Grid>
                <Grid item xs={12} sm={6} className="inputGrid">
                  <FormControl
                    className="selectformControlExp"
                    type="text"
                    name="total_experience"
                    value={total_experience}
                    placeholder="Total professional experience"
                    // className="inputField"
                    // required
                  >
                    <Select
                      className="selectforeducation"
                      defaultValue={"total_experience"}
                      onChange={onChangeTExp}

                      // inputProps={{ "aria-label": "Without label" }}
                    >
                      <MenuItem value="total_experience" disabled>
                        Total Experience
                      </MenuItem>
                      <MenuItem value="0-1 years">0-1 years</MenuItem>
                      <MenuItem value="1-2 years">1-2 years</MenuItem>
                      <MenuItem value="2-3 years">2-3 years</MenuItem>
                      <MenuItem value="3-4 years">3-4 years</MenuItem>
                      <MenuItem value="4-5 years">4-5 years</MenuItem>
                      <MenuItem value="5-10 years">5-10 years</MenuItem>
                      <MenuItem value="10+ years">10+ years</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                {/* <Grid item xs={12} sm={6} className="inputGrid">
                  <input
                    name="vacancy_source"
                    value={vacancy_source}
                    onChange={onChange}
                    placeholder="How do you learned about vacancy"
                    className="inputField"
                    // required
                    maxLength={50}
                  />
                </Grid> */}
              </Grid>
              {/* <Grid container spacing={2} className="inputGrid1">
                <Grid item xs={12} sm={6} className="inputGrid">
                  <DatePicker
                    selected={dob}
                    // locale={selectedDateLang}
                    dateFormat="dd/MM/yyyy"
                    onChange={onChangeDate}
                    className="inputField"
                    name="dob"
                    value={dob}
                    autoComplete="off"
                    placeholderText="Date of Birth"
                    openToDate={new Date("1999/01/01")}
                  />
                </Grid>
                <Grid item xs={12} sm={6} className="inputGrid">
                  <input
                    type="String"
                    name="post"
                    value={post}
                    onChange={onChange}
                    placeholder="Applying for the post"
                    className="inputField"
                    // required
                    maxLength={25}
                  />
                </Grid>
              </Grid> */}
              {/* <Grid container spacing={2} className="">
                <Grid item xs={12} sm={6} className="inputGrid">
                  <input
                    type="String"
                    name="post"
                    value={post}
                    onChange={onChange}
                    placeholder="Applying for the post"
                    className="inputField"
                    // required
                    maxLength={25}
                  />
                </Grid>

                 <Grid item xs={12} sm={6} className="inputGrid">
                  <Tooltip title={longText}>
                    <input
                      type="text"
                      name="sect"
                      value={sect}
                      onChange={onChange}
                      placeholder="Religion"
                      className="inputField"
                      // required
                      maxLength={25}
                    />
                  </Tooltip>
                </Grid> 
              </Grid> */}

              <Grid container spacing={2} className="">
                {/* <Grid item xs={12} sm={6} className="inputGrid">
                  <DatePicker
                    selected={joining_time}
                    // locale={selectedDateLang}
                    dateFormat="MMM-dd-yyyy"
                    onChange={onChangeeDate}
                    className="inputField"
                    name="joining_time"
                    value={joining_time}
                    autoComplete="off"
                    placeholderText="Expected date of joining"
                    minDate={moment().toDate()}
                    openToDate={new Date()}
                    // required
                  />
                </Grid> */}
              </Grid>
              <Grid container spacing={2} className="inputgrid1">
                <Grid item xs={12} sm={6} className="inputGrid">
                  {/* <input
                    type="text"
                    name="total_experience"
                    value={total_experience}
                    onChange={onChange}
                    placeholder="Total professional experience"
                    className="inputField"
                    // required
                  /> */}
                </Grid>
                {/* <Grid item xs={12} sm={6} className="inputGrid"> */}
                {/* <input
                    type="text"
                    name="post_experience"
                    value={post_experience}
                    onChange={onChange}
                    // maxlength="11"
                    placeholder="Experience related to the post"
                    className="inputField"
                    // required
                  /> */}
                {/* <FormControl
                    className="selectformControlExp"
                    type="text"
                    name="post_experience"
                    value={post_experience}
                    placeholder="Experience related to the post"
                  > */}
                {/* <Select
                      className="selectforeducation"
                      defaultValue={"post_experience"}
                      onChange={onChangePExp} */}

                {/* inputProps={{ "aria-label": "Without label" }} */}
                {/* > */}
                {/* <MenuItem value="post_experience" disabled>
                        Post Experience
                      </MenuItem>
                      <MenuItem value="0-1 years">0-1 years</MenuItem>
                      <MenuItem value="1-2 years">1-2 years</MenuItem>
                      <MenuItem value="2-3 years">2-3 years</MenuItem>
                      <MenuItem value="3-4 years">3-4 years</MenuItem>
                      <MenuItem value="4-5 years">4-5 years</MenuItem>
                      <MenuItem value="5-10 years">5-10 years</MenuItem>
                      <MenuItem value="10+ years">10+ years</MenuItem>
                    </Select>
                  </FormControl> */}
                {/* </Grid> */}
                {/* <Grid item xs={12} sm={6} className="inputGrid">
                  <input
                    name="current_employer"
                    value={current_employer}
                    onChange={onChange}
                    placeholder="Current Employer"
                    className="inputField"
                    // required
                  />
                </Grid> */}
              </Grid>
              {/* <Grid container spacing={2} className="inputgrid1">
                <Grid
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  item
                  xs={12}
                  sm={6}
                  className="inputGrid"
                >
                  <TextField
                    type="text"
                    name="current_salary"
                    value={current_salary}
                    onChange={onChange}
                    placeholder="Current Salary"
                    inputProps={{
                      maxLength: 25,
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                    }}
                    variant="outlined"
                    fullWidth
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      border: "none",
                      background: "#ffffff",
                      color: "#000000",
                      fontSize: "15px",
                      borderRadius: "10px",
                      width: "89%",
                      height: "45px",
                      padding: "2px ",
                    }}
                  />
                </Grid>
                <Grid
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  item
                  xs={12}
                  sm={6}
                  className="inputGrid"
                >
                  <TextField
                    type="text"
                    name="expected_salary"
                    value={expected_salary}
                    onChange={onChange}
                    placeholder="Expected Salary"
                    className="inputField"
                    inputProps={{
                      maxLength: 25,
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                    }}
                    variant="outlined"
                    fullWidth
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      border: "none",
                      background: "#ffffff",
                      color: "#000000",
                      fontSize: "15px",
                      borderRadius: "10px",
                      width: "89%",
                      height: "45px",
                      padding: "2px ",
                    }}
                  />
                </Grid>
              </Grid> */}
              {/* <Grid container spacing={2} className="inputgrid1"> */}
              {/* <Grid item xs={12} sm={6} className="inputGrid">
                  <FormControl
                    className="selectformControl"
                    variant="outlined"
                    name="marital_status"
                    value={marital_status}
                  >
                    <Select
                      defaultValue={"marital_status"}
                      className="selectforeducation"
                      onChange={onChangeMStatus}

                      // inputProps={{ "aria-label": "Without label" }}
                    >
                      <MenuItem value="marital_status" disabled>
                        Marital Status
                      </MenuItem>
                      <MenuItem value="single">Single</MenuItem>
                      <MenuItem value="married">Married</MenuItem>
                    </Select>
                  </FormControl>
                </Grid> */}
              {/* <Grid item xs={12} sm={6} className="inputGrid">
                  <input
                    type="skills"
                    name="skills"
                    value={formData.skills}
                    onChange={onChange}
                    placeholder="Test Category"
                    className="inputField"
                  />
                </Grid> */}
              {/* <Grid item xs={12} sm={6} className="inputGrid">
                  <input
                    type="text"
                    name="address"
                    value={address}
                    onChange={onChange}
                    placeholder="Address"
                    className="inputField"
                  />
                </Grid> */}
              {/* </Grid> */}
              {/* <Grid
                xs={12}
                sm={12}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <TextareaAutosize
                  aria-label="minimum height"
                  minRows={4}
                  maxLength={500}
                  onChange={onChange}
                  value={leaving_reason}
                  name="leaving_reason"
                  placeholder="Reason for leaving current job"
                  style={{
                    width: "92%",
                    padding: "15px",
                    color: "#000000",
                    background: "#ffffff",
                    // marginLeft: "14px",
                    marginTop: "10px",
                    borderRadius: "10px",
                    border: "none",
                    outline: "none",
                    marginBottom: "10px",
                    fontSize: "16px",
                  }}
                />
              </Grid> */}

              <div
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center ",
                  justifyContent: "center",
                }}
              >
                <Grid container spacing={2} className="formbuttonsGrid">
                  <Grid xs={12} sm={3}>
                    <div
                      size="large"
                      className="formbackbutton"
                      onClick={() => setStep(0)}
                    >
                      <img src={arrowleft} className="arrowleft" />
                      <b>Back</b>
                    </div>
                  </Grid>

                  <Grid
                    xs={12}
                    sm={6}
                    style={{
                      textAlign: "right",
                    }}
                  >
                    <button
                      variant="contained"
                      className="formSubmitButton"
                      size="large"
                      type="submit"
                      disabled={loading}
                      style={{ opacity: `${loading ? "0.5" : "1"}` }}
                    >
                      <b>
                        {" "}
                        {loading ? (
                          <div className="circulardiv">
                            <CircularProgress size={20} className="" />
                          </div>
                        ) : (
                          "Proceed to Assessment"
                        )}
                      </b>
                    </button>
                  </Grid>
                </Grid>
              </div>
            </Grid>
          </form>
        </Grid>
      </Grid>
      <CustomizedDialogs
        open={open}
        setOpen={setOpen}
        setStep={setStep}
        setActiveStep={setActiveStep}
      />
    </>
  );
};
export default UserForm;
